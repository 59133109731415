* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  background-color: #f7f7f7;
}

.App-header {
  background-color: #282c34;
  width: 100%;
  padding: 20px;
  color: white;
}

h1 {
  margin: 0;
  font-size: 24px;
}

/* Add this to ensure the file upload container styles are not affected */
.file-upload-container {
  width: 300px;
  margin-top: 20px;
  text-align: center;
}

.file-drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.file-drop-zone.dragging {
  background-color: #f0f0f0;
}

.file-input {
  display: none;
}

.file-label {
  font-size: 16px;
  color: #333;
}

.upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.upload-button:disabled {
  background-color: #ccc;
}

.upload-button:not(:disabled):hover {
  background-color: #0056b3;
}

.progress-container {
  margin-top: 20px;
}

.progress-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-item span {
  flex: 1;
  text-align: left;
}

progress {
  width: 100%;
  margin-left: 10px;
}